.Toastify__toast-container {
  width: 550px;
}
.Toastify__toast--default {
  background: #fff;
}

.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: var(--color-green);
}

.Toastify__toast--warning {
  background: var(--color-yellow);
}

.Toastify__toast--error {
  background: var(--color-red);
}

.Toastify__close-button {
  margin-left: 8px;
}
