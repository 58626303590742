// List of all default variables - https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

// these variables live now in webpack.config.js

//@primary-color: #006366; // unfortunately we cannot reuse color variables here
//@font-family: 'Noto Sans', sans-serif; // ant-design re-defines global font family
//@label-required-color: #000;
//@breadcrumb-last-item-color: #006366;
//@heading-color: #4a4a4a;
//
//@table-padding-vertical: 10px;
//@table-padding-horizontal: 10px;
//
//@menu-highlight-color: #006366;
//@menu-item-active-bg: #e2eff3;
//@menu-item-height: 50px;
//@menu-horizontal-line-height: 50px;
//@menu-inline-toplevel-item-height: 50px;
//@menu-item-active-border-width: 0;
//@menu-item-vertical-margin: 0;
//@menu-item-boundary-margin: 0;

body {
  line-height: 1.5715;
}
html {
  line-height: 1.15;
}
.ant-breadcrumb-link a {
  text-decoration: none;
}
// open submenu background color
.aant-menu-submenu-open {
  background-color: #e2eff3;
}

// open submenu content background color
.aant-menu-submenu > .ant-menu.ant-menu-sub {
  background-color: #e2eff3;
}

// hovered menu item background color
.ant-menu > .ant-menu-item:hover {
  background-color: #e2eff3;
}

// remove inset from submenu item
.aant-menu-sub.ant-menu-inline > .ant-menu-item {
  padding-left: 24px !important;
}

// submenu item color
.ant-menu .ant-menu-submenu-title a {
  color: #4a4a4a;
}

// active submenu title color
.ant-menu .ant-menu-submenu-title a.active {
  color: #006366;
}

// hovered submenu item color
.ant-menu .ant-menu-submenu-title:hover a {
  color: #006366;
}

// active submenu item color
.ant-menu-item a.active {
  color: #006366;
}

.ant-form-item-extra {
  height: 16px;
}

.ant-select-item-option .ant-select-item-option-content {
  white-space: normal;
}

.ant-form-horizontal .ant-form-item-control {
  flex: 1 1;
}

.ant-collapse-header-text {
  flex-grow: 1;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: inherit;
}

.ant-menu-title-content {
  font-size: 14px;
}
.ant-menu-title-content > a {
  text-decoration: none;
}
.ant-menu-title-content > a > span.anticon,
.ant-menu-title-content > span.anticon {
  margin-right: 10px;
  font-size: 14px;
  min-width: 14px;
  transition:
    font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-rightTop.ant-zoom-big-leave.ant-zoom-big-leave-active.ant-zoom-big {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .anticon
  + span,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon
  + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}

.ant-menu-title-content > div {
  text-overflow: ellipsis;
  overflow: hidden;
}
.ant-table-cell > a {
  text-decoration: none;
}

.ant-pagination-item > a,
.ant-dropdown-menu-title-content > a {
  text-decoration: none;
}

.ant-form-item .ant-form-item-label > label::after {
  display: none;
}

.ant-form-item-label > label {
  margin-right: 15px;
}

@primary-color: #006366;@font-family: "Noto Sans", sans-serif;@label-required-color:  #000;@breadcrumb-last-item-color: #006366;@heading-color: #4a4a4a;@table-padding-vertical: 10px;@table-padding-horizontal: 10px;@menu-highlight-color: #006366;@menu-item-active-bg: #e2eff3;@menu-item-height: 50px;@menu-horizontal-line-height: 50px;@menu-inline-toplevel-item-height: 50px;@menu-item-active-border-width: 0;@menu-item-vertical-margin: 0;@menu-item-boundary-margin: 0;